(function () {
  'use strict';

  class EditStudents {
    constructor($mdDialog, $document) {
      let vm = this;
      vm.$mdDialog = $mdDialog;
      vm.body = $document[0].body;
    }

    show(ev, student, callback) {
      const vm = this;
      vm.obj = student;
      vm.$mdDialog.show({
        controller: 'EditStudentsCtrl',
        controllerAs: 'editStudents',
        templateUrl: 'components/edit-students/edit-students.tpl.html',
        parent: angular.element(vm.body),
        targetEvent: ev,
        clickOutsideToClose: true
      })
        .then(callback);
    }
    getObj() {
      const vm = this;
      return vm.obj;
    }
  }

  /**
   * @ngdoc service
   * @name components.service:EditStudents
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('EditStudents', EditStudents);
}());
